@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  position: relative;
  -moz-osx-font-smoothing: grayscale;
  background: url("https://superstaking-website.s3.us-east-2.amazonaws.com/images/app/app-bg.png");
  background-size: cover !important;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  overflow: auto;
  overflow-x: hidden;
  height: 100%;
  -ms-scroll-chaining: none;
      overscroll-behavior: none;
}

@media only screen and (min-width: 720px) {
  body {
    background: url("https://superstaking-website.s3.us-east-2.amazonaws.com/images/app/app-bg-27.png");
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  overflow: scroll;
  overflow-x: hidden;
  overflow: hidden;
  height: 100%;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}

a {
  text-decoration: none !important;
  color: inherit !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: "rgba(255, 255, 255, 0.15)";
  width: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* background: #fa0339; */
  border-radius: 10px;
}

.MuiButton-root {
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

iframe {
  display: none;
}

.App {
  text-align: center;
  padding: 20px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.MuiBox-root {
  /* width: 100%; */
  /* background: linear-gradient(
    182.75deg,
    rgba(96, 83, 132, 0.7) -94.02%,
    rgba(15, 8, 36, 0.7) 153.25%
  ) !important ;

  background-color: #0f0824 !important;
  color: #fff !important; */
}
.MuiFormHelperText-root {
  position: absolute;
  bottom: -24px;
  margin: 5px 5px !important;
}

.MuiGrid-root {
  display: flex;
}

.MuiCardContent-root:last-child {
  overflow: hidden;
}

.MuiAlert-icon {
  /* color: #0aebf2 !important; */
}
/* snackBar: {
  "& .MuiAlert-root": {
    backgroundColor: "red !important",
  },
  "& .MuiAlert-standardSuccess ": {
    backgroundColor: "red !important",
  },
}, */
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.approve-container {
}

