.App {
  text-align: center;
  padding: 20px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.MuiBox-root {
  /* width: 100%; */
  /* background: linear-gradient(
    182.75deg,
    rgba(96, 83, 132, 0.7) -94.02%,
    rgba(15, 8, 36, 0.7) 153.25%
  ) !important ;

  background-color: #0f0824 !important;
  color: #fff !important; */
}
.MuiFormHelperText-root {
  position: absolute;
  bottom: -24px;
  margin: 5px 5px !important;
}

.MuiGrid-root {
  display: flex;
}

.MuiCardContent-root:last-child {
  overflow: hidden;
}

.MuiAlert-icon {
  /* color: #0aebf2 !important; */
}
/* snackBar: {
  "& .MuiAlert-root": {
    backgroundColor: "red !important",
  },
  "& .MuiAlert-standardSuccess ": {
    backgroundColor: "red !important",
  },
}, */
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.approve-container {
}
