@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  position: relative;
  -moz-osx-font-smoothing: grayscale;
  background: url("https://superstaking-website.s3.us-east-2.amazonaws.com/images/app/app-bg.png");
  background-size: cover !important;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  overflow: auto;
  overflow-x: hidden;
  height: 100%;
  overscroll-behavior: none;
}

@media only screen and (min-width: 720px) {
  body {
    background: url("https://superstaking-website.s3.us-east-2.amazonaws.com/images/app/app-bg-27.png");
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  overflow: scroll;
  overflow-x: hidden;
  overflow: hidden;
  height: 100%;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}

a {
  text-decoration: none !important;
  color: inherit !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: "rgba(255, 255, 255, 0.15)";
  width: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* background: #fa0339; */
  border-radius: 10px;
}

.MuiButton-root {
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

iframe {
  display: none;
}
